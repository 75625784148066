import { getItemLocalStorage } from '@cigam/template/dist/utils/getItemLocalStorage';

const settingsStorage = getItemLocalStorage('settings');

let url;

// Production follows the config.json URL, configured on App.js
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  url = process.env.REACT_APP_DOMAIN ?? '';
} else {
  url = settingsStorage?.apiUrl ?? '';
}

export const APP_VERSION = '2.0.0';
export const BASE_URL = url;
export const API_BASE_URL = `${BASE_URL}/api`;
export const DEBUG_BASE_URL = `${BASE_URL}/apm`;
export const SIGNALR_BASE_URL = '/SignalR';

export const MODULE = {
  portalservicos: {
    location: '/portalservicos',
    name: 'Serviços',
    type: 'cigam',
  },
  portalprojetos: {
    location: '/portalprojetos',
    name: 'Gestão de Projetos',
    type: 'cigam',
  },
  portalindustria: {
    location: '/portalindustria',
    name: 'Indústria',
    type: 'cigam',
  },
  portalcliente: {
    location: '/portalcliente',
    name: 'Cliente',
    type: 'contato',
  },
  desktop: {
    location: '/desktop',
    name: 'Desktop',
    type: 'cigam',
  },
};

export default {};
